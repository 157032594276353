<template>
	<div class="app-container app-theme-white body-tabs-shadow">
		<div class="app-container">
			<div class="container">
				<div class="d-flex">
					<div class="text-center mt-4 float-left" v-if="systemMode === 'whitelabel'">
						<img class="img-fluid h-12 logo-top pl-5" :src="GET_COMPANY_SUBSCRIPTION_SETTING.full_logo"
							 alt="">
						<!-- <img class="img-fluid h-12 logo-top pl-5" src="./../../assets/images/logo.svg" alt=""> -->
					</div>
					<div class="text-center mt-4 float-left" v-else>
						<img class="img-fluid h-12 logo-top pl-5" src="./../../assets/images/logo.png" alt="">
						<!-- <img class="img-fluid h-12 logo-top pl-5" src="./../../assets/images/logo.svg" alt=""> -->
					</div>
				</div>
				<div class="container mb-5">
					<div class="row">
						<div class="col-md-9 mx-auto">
							<div
								class="mt-24 text-gray-700 dark:text-gray-400 md:mt-10 text-4xl font-weight-bolder header-text">
								{{ $t('privacyPolicy') }}
							</div>
							<h3 class="sub-text mt-2 mb-2 font-weight-bold">TJÄNSTEAVTAL - ALLMÄNT</h3>
							<p class="text-content">Det här avtalet gäller när vi på Connectivo AB, org. nr.
								556932-7777, tillhandahåller tjänster till er. I detta Avtal betyder ”vi”, ”oss” ,
								"leverantören", ”B2B Portal" Connectivo AB och ”ni”, ”er” eller ”kunden” ni som
								kund. </p>
							<h3 class="sub-text mt-2 mb-2 font-weight-bold">DEFINITIONER</h3>
							<p class="text-content">Nedan listas en del ord som används genomgående i Avtalet. Orden ska
								ha den betydelse som anges nedan. </p>
							<h3 class="sub-text mt-2 mb-2 font-weight-bold">Anslutningspunkten</h3>
							<p class="text-content">Den punkt där våra Tjänster görs tillgängliga för er. </p>
							<h3 class="sub-text mt-2 mb-2 font-weight-bold">Användare</h3>
							<p class="text-content">Den person som ni har givit tillgång till Tjänsterna. Användare ska
								vara anställd hos er, eller annars jobba för er räkning, till exempel en konsult,
								anställd eller redovisningsekonom. </p>
							<h3 class="sub-text mt-2 mb-2 font-weight-bold">Avtal</h3>
							<p class="text-content">Det här avtalet inklusive bilagor och underbilagor. </p>
							<h3 class="sub-text mt-2 mb-2 font-weight-bold">Kunddata</h3>
							<p class="text-content">Data som ni på något sätt, själv eller genom Användare, överlämnar
								till oss eller som vi annars får del av genom er användning av Tjänsterna.</p>
							<h3 class="sub-text mt-2 mb-2 font-weight-bold">Systemadministratör</h3>
							<p class="text-content">Den person som tecknat Tjänsterna för er räkning, eller som annars
								har utsetts av er till Systemadministratör. </p>
							<h3 class="sub-text mt-2 mb-2 font-weight-bold">Tjänsten eller Tjänsterna</h3>
							<p class="text-content">Molnbaserad prenumerationstjänst avseende en ÅF plattform för
								grossister och återförsäljare. Tjänsten innefattar leverans av en standardiserad
								plattform. Skräddarsydda och kundspecifika applikationer innefattas ej av denna tjänst,
								utan kan beställas som en separat tjänst.</p>
							<h3 class="sub-text mt-2 mb-2 font-weight-bold">Systemadministratör</h3>
							<p class="text-content">Den person som tecknat Tjänsterna för er räkning, eller som annars
								har utsetts av er till Systemadministratör. </p><br>
							<h3 class="sub-text mt-2 mb-2 font-weight-bold">AVTALETS INGÅENDE OCH BESTÄLLNING</h3>
							<p class="text-content">Avtal avseende Tjänsten ska ingås skriftligen antingen via e-post,
								via beställning på hemsidan, www.b2bportal.se, eller via App Marknadsplatser. Den dag
								Kunden fullföljt beställningen utgör ”Avtalsdagen”. Kundens minimiåtagande per månad
								enligt Avtalet ska beräknas som summan av de månadsavgifter som Kunden ska betala för
								det totala antal licenser som Kunden beställt vid Avtalets ingående.
								Kundens minimiåtagande per månad framkommer i prislistan som är publicerad på hemsidan.
								Beställning av ytterligare/nya tjänster (inklusive licenser för användare och abonnemang
								utöver Kundens minimiåtagande) eller Produkter efter det att Avtalet ingåtts ska göras
								på det sätt Leverantören anvisar. </p>
							<h3 class="sub-text mt-2 mb-2 font-weight-bold">ÅNGERRÄTT</h3>
							<p class="text-content">Nedladdning och nyttjande av digitala produkter klassas som en
								tjänst som inte kan återlämnas då det är en så kallad direktkonsumtion. Den digitala
								verifikationsprocessen motsvarar en plombering av en fysisk vara. </p>
							<h3 class="sub-text mt-2 mb-2 font-weight-bold">LEVERANS</h3>
							<p class="text-content">Tjänstens konfiguration och aktivering levereras i form av en
								kundportal där kund ansvarar för att följa anvisningar och konfigurera sina
								inställningar. Om Kunden anser att det finns fel eller brister i leveransen av Tjänsten,
								ska Kunden skriftligen informera Leverantören därom och tillse att relevant skriftlig
								dokumentation och felbeskrivning är Leverantören tillhanda senast tio (10) dagar räknat
								från Leveransdatum. Leverantören ska därefter och inom skälig tid vidta nödvändiga
								korrigerande åtgärder. Om Kunden inte anmält fel eller brist i enlighet med ovan anses
								Tjänsten felfritt levererad. Fel eller ändringar efter nyssnämnda tid hanteras enligt
								vad som anges under punkt 16 (Felavhjälpning) nedan. Om leverans inte kan ske och detta
								beror på att Kunden inte fullgjort sina åtaganden enligt Avtalet äger Leverantören rätt
								att fakturera samtliga avgifter från Avtalsdagen. </p>
							<h3 class="sub-text mt-2 mb-2 font-weight-bold">LEVERANSGARANTI</h3>
							<p class="text-content">Om Leveransdatum inte ägt rum senast nittio (90) dagar från
								Avtalsdagen, har Kunden rätt att kräva avdrag med belopp motsvarande femtio (50) procent
								av den obrukbara Tjänstens erlagda fasta avgift. Om Leveransdatum inte ägt rum senast
								etthundratjugo (120) dagar från Avtalsdagen, har Kunden rätt att häva Avtalet. Om
								Avtalet hävs ska samtliga prestationer återgå och samtliga förhållanden anses reglerade,
								bl.a. med innebörd att ingen ytterligare ersättning utöver att av Kunden eventuellt
								erlagda avgifter ska återbetalas till Kunden. Leveransgarantin i denna punkt gäller
								endast om förseningen enligt ovan uteslutande beror på förhållanden hänförliga till
								Leverantören. Så anses inte vara fallet exempelvis om Förseningen helt eller delvis är
								hänförlig till annan tredjepart än Leverantören eller om förseningenhelt eller delvis är
								hänförlig till Kunden, vilket kan vara fallet om Kunden påverkar eller ändrardatum för
								Leveransdatum eller Kunden inte uppfyllt sina åtaganden enligt Avtalet,
								såsominstallation och aktivering av tjänsten. Uppgift i Avtalet eller i
								Kunddatainformation är felaktig och Leverantören inte kan få korrekt uppgift (bekräftad)
								av Kunden före planerad installation.</p>
							<br>
							<h3 class="sub-text mt-2 mb-2 font-weight-bold">KUNDENS ÅLIGGANDEN </h3>
							<p class="text-content mt-1">Kunden ska se till att alla uppgifter som anges angående
								kontaktuppgifter, faktureringsinformationeller kreditkortsinformation är korrekta och
								förbinder sig att uppdatera dessa uppgifter regelbundet Kunden skall ansvara för den
								verksamhet som bedrivs av Kunden och dess medlemmar och ska säkerställa att den
								nationella lagstiftningen följs. Kunden ansvarar för att kontinuerligt kontrollera
								importerad data till ekonomiprogrammet ochhålla uppdaterad dess programinställningar för
								brukande av programvara. Kunden ansvarar för att inställningar i ekonomiprogrammet
								ställs in i enlighet med instruktionersom tillhandahålls av B2B Portal och aktivering av
								levererad tjänst. Kunden samtycker till att inte reproducera, duplicera, kopiera, sälja,
								återförsälja eller exploatera någon del av b2bportal.se, användning av b2bportal.se,
								eller tillgång till b2bportal.se. Återförsäljning av b2bportal.se är endast tillåtet med
								skriftligt samtycke från Connectivo AB och ett återförsäljaravtal från Connectivo AB
								(som Connectivo AB förbehåller sig rätten att när som helst avsluta och som kan omfatta
								vissa villkor). Den som undertecknar detta avtal genom köp av tjänst garanterar att
								han/hon är ansvarig för att se till att tillstånd finns för att binda Kunden genom ett
								sådant utförande Kunden ansvarar för att hålla användarinformation och lösenord väl
								skyddade så användande av obehörig förhindras. Kunden ansvarar för att informera berörda
								användare hos Kunden om att Leverantören kan komma att skicka information om
								Leverantörens produkter och tjänster. </p>
							<h3 class="sub-text mt-2 mb-2 font-weight-bold">ANSVAR FÖR EVENTUELLA KRAV</h3>
							<p class="text-content mt-1">Om någon annan riktar krav mot oss på grund av er eller era
								Användares användning av Tjänsterna eller tredje parts tjänster ska ni hålla oss
								skadelösa genom att ersätta oss för den skada eller förlust vi lidit på grund av kravet.
								Exempel på sådana krav är begäran om ersättning för intrång i tredje parts immateriella
								rättigheter så som upphovsrätt eller varumärke. Vi ska även ha rätt att ge vidare rätten
								att framställa sådan begäran och därmed låta någon annan framställa begäran.</p>
							<h3 class="sub-text mt-2 mb-2 font-weight-bold">KUNDDATAINFORMATION</h3>
							<p class="text-content mt-1">Leverantören behandlar Kunddatainformation för att
								tillhandahålla och säkerställa driften av Tjänsten enligt Avtalet, för att fullgöra
								skyldighet enligt lag eller annan författning samt för fakturering. Leverantören får
								använda Kunddata för att fullgöra uppdraget, för statistiska ändamål, för att förbättra
								och utveckla våra Tjänster samt för marknadsföring. Kunden ska på Leverantörens begäran
								lämna den kunddatainformation som Leverantören behöver för att tillhandahålla Tjänsten
								(”Kunddatainformation”). Kunden svarar för att samtlig Kunddatainformation är korrekt
								samt för att att Kunddata är fri från virus, trojaner, maskar eller annan programvara
								eller kod som kan skada Tjänsterna. Kund ska även ersätta Leverantör för all skada som
								åsamkas direkt eller indirekt på grund av att innehållet i Kunddata eller användningen
								av Kunddata i Tjänsterna strider mot tillämplig lagstiftning. Som kund kan ni alltid ta
								ut Kunddata från Tjänsterna. I samband med att ni säger upp Avtalet är det upp till er
								att ta ut all Kunddata från Tjänsterna som ni kan komma att behöva för framtida
								bruk.</p>
							<h3 class="sub-text mt-2 mb-2 font-weight-bold">LEVERANS</h3>
							<p class="text-content mt-1">Tjänstens konfiguration och aktivering levereras i form av en
								kundportal där kund ansvarar för att följa anvisningar och konfigurera sina
								inställningar. Om Kunden anser att det finns fel eller brister i leveransen av Tjänsten,
								ska Kunden skriftligen informera Leverantören därom och tillse att relevant skriftlig
								dokumentation och felbeskrivning är Leverantören tillhanda senast tio (10) dagar räknat
								från Leveransdatum. Leverantören ska därefter och inom skälig tid vidta nödvändiga
								korrigerande åtgärder. Om Kunden inte anmält fel eller brist i enlighet med ovan anses
								Tjänsten felfritt levererad. Fel eller ändringar efter nyssnämnda tid hanteras enligt
								vad som anges under punkt 16 (Felavhjälpning) nedan. Om leverans inte kan ske och detta
								beror på att Kunden inte fullgjort sina åtaganden enligt Avtalet äger Leverantören rätt
								att fakturera samtliga avgifter från Avtalsdagen.</p>
							<h3 class="sub-text mt-2 mb-2 font-weight-bold">PRISER OCH BETALNING</h3>
							<p class="text-content mt-1">Såvida inte parterna kommit överens om annat gäller de priser
								för Tjänsterna som vid tidpunkten som parterna ingåt Avralet publicerats på B2B Portal
								hemsida. Detsamma gäller vid förlängning av Avtalet. Tjänstens fasta avgifter debiteras
								månadsvis eller årsvis, antingen via kortbetalning eller faktura. Betalningsvillkor för
								faktura är 15 dagar, såvida inte Parterna kommit överens om något annat. Vid betalning
								efter förfallodagen debiteras dröjsmålsränta enligt lag. Leverantören äger dessutom rätt
								till ersättning för påminnelseavgift och inkassokostnader. Invändning mot faktura ska
								vara Leverantören tillhanda senast tio (10) dagar efter fakturadatum. Invändning ska
								göras skriftligen med angivande av skäl till varför Kunden invänder mot fakturan.
								Leverantören har rätt att med omedelbar verkan säga upp Avtalet om betalning av faktura
								inte har skett trettio (30) dagar efter förfallodatum. I samband med köp av applikation
								är det kundens ansvar att säkerställa att korrekt nivåpaket väljs så att tjänsten täcker
								kundens behov. Connectivo AB förbehåller sig rätten att i efterhand fakturera kunden i
								de fall kunden överstiger den volym som valt nivåpaket täcker. Vi har rätt att höja
								priset, med direkt verkan, om höjningen beror på omständigheter vi inte kan kontrollera,
								till exempel förändring av valutakurs, skatt eller annan omständighet av ekonomisk
								betydelse som påverkar kostnaden för att tillhandahålla Tjänsterna. Vi har också rätt
								att höja priset i andra fall men då måste vi kontakta er senast trettio (30) dagar innan
								vi höjer priset för att informera er om förändringen. Det nya priset börjar gälla först
								när nästa betalningsperiod börjar. Ni har möjlighet att till dagen innan prishöjningen
								träder ikraft säga upp Avtalet med oss. Om ingen uppsägning görs av er, anses ni ha
								godkänt den nya prissättningen.</p>
							<h3 class="sub-text mt-2 mb-2 font-weight-bold">AVTALSTID OCH UPPSÄGNING AV AVTALET</h3>
							<p class="text-content mt-1">Det utgår ingen bindningstid och kunden är fri att avsluta
								avtalet det datum de önskar. Avtalet ska sägas upp skriftligen. Utöver vad som annars
								anges i detta Avtal har vi båda rätt att med omedelbar verkan säga upp Avtalet om den
								andra av oss har: brutit mot Avtalet och inte rättar till det inom trettio (30) dagar
								efter att ha mottagit den andra partens skriftliga meddelande om avtalsbrott, eller
								inleder likvidationsförfarande, ansöker om eller försätts i konkurs, ställer in
								betalningarna eller på annat sätt kan befaras vara på väg mot obestånd. </p>
							<h3 class="sub-text mt-2 mb-2 font-weight-bold">SUPPORT/FELAVHJÄLPNING</h3>
							<p class="text-content mt-1">I Tjänsten ingår, utöver tillgång till serviceportalen som nås
								via www.b2bportal.se, teknisk och administrativ support via mail och telefon som avser
								att hjälpa Kunden med frågor avseende Tjänsten, bistå med nödvändig support från
								Leverantörens leverantörer, enklare ändringar/konfigurationer och tillägg/borttag i
								Tjänsten samt felsökning i Tjänsten om Fel konstateras i samband med felsökningen. Ni
								kan vända er till oss med frågor relaterade till hur våra Tjänster fungerar och ska
								användas. Vi kan däremot inte erbjuda support i frågor om till exempel bokföring eller
								frågor av teknisk karaktär som inte handlar om Tjänsten. Ni kan kontakta oss genom de
								kontaktuppgifter som finns på vår hemsida. Tjänsten omfattar inte support som bland
								annat men ej uteslutande innebär: felsökning i Kundens, tredje mans eller annan
								leverantörs program, support avseende Kundens utrustning, konfiguration som inte
								tillhandahållits av Leverantören, support som inte avser Tjänsten, utbildning, längre
								telefonmöten, rådgivning eller konsultation. Sådan support debiteras i enlighet med
								Leverantörens vid var tid gällande standardprislista Med ”Fel” avses att Kunden inte kan
								använda Tjänsten i enlighet med Avtalet och att felet beror på Leverantören.
								Leverantören ska avhjälpa Fel kostnadsfritt inom skälig tid. Med Fel avses inte,
								exempelvis men ej uteslutande, brister som: är av mindre betydelse för Kunden eller som
								inte hindrar Kunden från att använda Tjänsten, orsakats av tredje man eller genom
								omständigheter utanför Leverantörens kontroll. avser annat än Tjänsten, exempelvis
								avseende tjänster, produkter och leveranser som sker genom annan leverantör.
								Felanmälningar hanteras av Leverantörens support. Felanmälan sker genom e-post eller
								telefonsamtal. Det åligger Kunden att vara supporten behjälplig i felsökning och
								återkoppla inom 48 timmar med nödvändig information för fortlöpande sökning. Om
								återkoppling inte sker stängs ärendet och betraktas som avslutat utan ansvar för
								Leverantören. Felanmälan kan göras dygnet runt, alla årets dagar på info@b2bportal.se.
								Felanmälan som inkommer på annan tid än på helgfri vardag mellan 08.00-17.00 anses
								inkommen nästkommande helgfri vardag kl. 08.00.</p>
							<h3 class="sub-text mt-2 mb-2 font-weight-bold">ANSVAR FÖR BOKFÖRINGSDATA</h3>
							<p class="text-content mt-1">Leverantören ansvarar för vidareutveckling av Tjänsten och
								bestämmer ensam vilka förbättringar, förändringar och tekniska anpassningar som ska
								göras i Tjänsten. B2B Portal och dess funktionalitet grundas i kontinuerlig
								efterforskning, maskininlärning samt kontinuerlig systemutveckling. Leverantören
								garanterar ej att tolkning av transaktionsdata alltid blir fullständigt korrekt, utan
								Kunden ansvarar själv för slutlig kontroll av bokföringens korrekthet. Med förbehåll för
								de begränsningar som anges i detta avtal, skall Leverantören vara ansvarig för
								korrigering av felbokningar till följd av dess försumlighet. I händelse av fel eller
								brister som kan tillskrivas Leverantören åtar denne sig att agera för att korrigera
								sådana fel utan oskäligt dröjsmål. I det fall det är fråga om ett fel i Tjänsterna som
								gör att bearbetningen av Kunddata leder till ett felaktigt resultat, så åtar vi oss att,
								så snart som omständigheterna kräver med hänsyn till typen av fel och omständigheterna i
								övrigt, utan extra kostnad för er, göra en ny bearbetning av Kunddata. Kunden har inte
								rätt till en minskning av betalning, debitering eller andra påföljder i händelse av
								driftstörningar eller felrapportering som kan uppstå. Felanmälan måste ges av kunden i
								enlighet med de instruktioner som aviserats av Leverantören från tid till tid och inom
								en rimlig tid för upptäckten av felet. I avsaknad av uppsåt eller grov oaktsamhet av
								Leverantören, antar denne annars inget ansvar för fel eller brister i tjänster som ingår
								däri.</p>
							<h3 class="sub-text mt-2 mb-2 font-weight-bold">ÄNDRING AV AVTALSVILLKOR</h3>
							<p class="text-content mt-1">Leverantören förbehåller sig vidare rätten att ändra villkoren
								i detta Tjänsteavtal, inklusive, men inte begränsat till, de priser som Leverantören har
								från en tid till en annan. Kunden skall informeras om sådana ändringar via e-post eller
								genom den information som görs tillgänglig på B2B Portal hemsida en (1) månad före
								ikraftträdandet. Om kunden inte godkänner ändringen, har Kunden inom trettio (30)
								kalenderdagar från avsändandet av e-post eller, i förekommande fall, trettio (30)
								kalenderdagar från ändringen publiceras på B2B Portal webbplats, rätt att säga upp
								avtalet med omedelbar verkan. Om avtalet inte sägs upp av Kunden inom nämnda tid, ska
								Kunden anses ha godkänt de nya villkoren. </p>
							<h3 class="sub-text mt-2 mb-2 font-weight-bold">ÖVERLÅTELSE AV AVTAL</h3>
							<p class="text-content mt-1">Kunden har inte rätt att utan Leverantörens föregående
								skriftliga medgivande överlåta, pantsätta eller på annat sätt disponera över eller
								upplåta säkerhetsrätt avseende sina rättigheter och/eller skyldigheter enligt Avtalet.
								Leverantören har rätt att överlåta och/eller på annat sätt disponera över sina
								rättigheter och/eller skyldigheter enligt Avtalet.</p>
							<h3 class="sub-text mt-2 mb-2 font-weight-bold">MARKNADSFÖRING</h3>
							<p class="text-content mt-1">Vi får marknadsföra tjänster till er som vi eller någon
								samarbetspartner till oss tillhandahåller. Marknadsföringen kan ske i Tjänsterna, genom
								e-post eller på annat sätt.</p>
							<h3 class="sub-text mt-2 mb-2 font-weight-bold">OMBUD OCH UNDERLEVERANTÖRER</h3>
							<p class="text-content mt-1">Leverantören har rätt att anlita underleverantörer och
								samarbetspartners för att utveckla Tjänsterna och fullgöra sina åtaganden enligt
								Avtalet </p>
							<h3 class="sub-text mt-2 mb-2 font-weight-bold">IMATERIELLA RÄTTIGHETER</h3>
							<p class="text-content mt-1">Samtliga immateriella rättigheter till Tjänsten samt ändringar
								och variationer därav, är och ska fortsatt vara Leverantörens egendom. Ni får inte
								kopiera, ändra eller på annat sätt hantera programvara eller annat material som hör till
								Tjänsterna. Vid immaterialrättsintrång som beror på Kunden gäller följande: Kund ska
								försvara Leverantör om krav riktas eller talan förs mot leverantör om intrång på grund
								av kunden eller den som använder Tjänsterna i strid med detta Avtal. Detta sker då på
								kundens egna bekostnad. Kund ska också ersätta Leverantör för alla de kostnader och
								skadestånd som Leverantör genom förlikning eller dom kan bli skyldiga att betala. </p>
							<h3 class="sub-text mt-2 mb-2 font-weight-bold">MEDDELANDEN</h3>
							<p class="text-content mt-1">Meddelanden under Avtalet lämnas till av Leverantören
								respektive Kunden angiven adress i Avtalet, såvida inte meddelanden med fördel lämnas på
								annat sätt, exempelvis via telefon eller e-mail. Meddelanden ska anses ha kommit den
								mottagande Parten tillhanda fem (5) dagar efter avsändandet med brev och tre (3) dagar
								efter avsändande med e-post och direkt via telefon. Parterna ska skyndsamt informera
								varandra om adressändringar</p>
							<h3 class="sub-text mt-2 mb-2 font-weight-bold">FORCE MAJEURE</h3>
							<p class="text-content mt-1">Leverantören ska inte vara ansvarig för underlåtenhet att
								fullgöra Avtalet om detta föranleds av en omständighet som legat utanför Leverantörens
								kontroll och som Leverantören inte skäligen kunde förväntas ha räknat med eller tagit i
								beaktande vid tiden för Avtalets ingående och vars följder Leverantören inte heller
								skäligen kunde ha undvikit eller övervunnit, såsom krig, brand, översvämning,
								åsknedslag, eldsvåda, terrordåd, hacker-attack, ockupation, lock-out,
								importbegränsningar, sanktioner eller liknande, eller fel eller förseningar i leverans
								från underleverantör på grund av omständigheter som denne ej kunnat råda över.</p>
							<h3 class="sub-text mt-2 mb-2 font-weight-bold">TILLÄMPLIG LAG OCH TVISTELÖSNING</h3>
							<p class="text-content mt-1">Parternas rättigheter och skyldigheter vid tolkning och
								tillämpning av Avtalet ska bestämmas i enlighet med svensk lag. Tvister som uppstår i
								anledning av Avtalet ska slutligt avgöras genom skiljedomsförfarande administrerat av
								Västsvenska Handelskammares Skiljedomsinstitut. Talan avseende förfallna obetalda
								fordringar äger dock Part anhängiggöra inför allmän domstol. Reglerna om förenklat
								skiljeförfarande ska tillämpas, om inte Västsvenska Handelskammarens Skiljeråd med
								beaktande av tvistens svårighetsgrad, tvisteföremålets värde och övriga omständigheter
								bestämmer att reglerna om ordinärt skiljeförfarande ska tillämpas. I sistnämnda fall ska
								Skiljerådet också bestämma om skiljenämnden ska bestå av en eller tre skiljemän.
								Skiljeförfarandets säte ska vara Göteborg, Sverige. Språket för förfarandet ska vara
								svenska. </p>
							<br>
							<p class="text-content mt-2">Version 1.0 Reviderad 2021.11.01 </p>
							<br>
							<br>
							<div class="row">
								<div class="col-md-5">
									<p>Information</p>
									<br>
									<br>
									<ul>
										<li><a href="/privacy-policy">Tjänsteavtal</a></li>
										<li><a href="/privacy-policy">Integritetspolicy</a></li>
										<li><a href="https://b2bportal.setmore.com/">Boka Demo eller Telefonmöte</a>
										</li>
									</ul>
								</div>
								<div class="col-md-5">
									<p1>B2B Portal är en SaaS plattform för Grossister och Återförsäljare.
										Varumärket B2B Portal ägs av Connectivo AB med säte i Göteborg.
										Organisationsnummer 556932-7777.
									</p1>
								</div>
								<div class="col-md-12 mt-3 mb-3">
									<p class="copyright" style="font-size: 15px" v-if="systemMode === 'whitelabel'">
										Copyright © {{ date }} {{ GET_COMPANY_SUBSCRIPTION_SETTING.brand_name }}</p>
									<p class="copyright" style="font-size: 15px" v-else>Copyright © {{ date }} B2B
										Portal</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
	name: 'PrivacyPolicy',
	data: function () {
		return {
			date: new Date().getFullYear(),
			systemMode: ''
		}
	},
	computed: {
		...mapGetters({
			GET_COMPANY_SUBSCRIPTION_SETTING: 'portal/GET_COMPANY_SUBSCRIPTION_SETTING'
		})
	},
	beforeMount() {
		this.systemMode = process.env.VUE_APP_SYSTEM_MODE
	},
}
</script>

<style scoped>
.sub-text {
	font-size: 1.4rem;
	margin-top: 15px !important;
	font-weight: 590;
}

.text-content {
	font-size: 15px;
	/*word-break: break-all;*/
}

@media screen and (max-width: 600px) {
	.sub-text {
		font-size: 1.1rem !important;
	}

	.header-text {
		font-size: 1.4rem;
	}
}
</style>
